import { createTheme } from '@mui/material/styles';

function shadeHexColor(color, percent) {
  const f = parseInt(color.slice(1), 16);
  const t = percent < 0 ? 0 : 255;
  const p = percent < 0 ? percent * -1 : percent;
  const R = f >> 16;
  const G = (f >> 8) & 0x00ff;
  const B = f & 0x0000ff;
  return `#${(
    0x1000000 +
    (Math.round((t - R) * p) + R) * 0x10000 +
    (Math.round((t - G) * p) + G) * 0x100 +
    (Math.round((t - B) * p) + B)
  )
    .toString(16)
    .slice(1)}`;
}

function theme(darkMode = false) {
  // Light Theme
  let background = '#f8f9fa';
  let primary = '#10a37f';
  let primaryContrastText = '#ffffff';
  let secondary = '#ffffff';
  let secondaryContrastText = '#10a37f';
  let primaryText = '#3D3D3D';
  let secondaryText = '#575757';
  let disabledText = '#858585';
  let paperBackground = '#ffffff';

  if (darkMode) {
    background = '#34393f';
    primary = '#3ab99b';
    primaryContrastText = '#ffffff';
    secondary = '#cdcdcd';
    secondaryContrastText = '#303030';
    primaryText = '#CFCFCF';
    secondaryText = '#B0B0B0';
    disabledText = '#B0B0B0';
    paperBackground = '#595959';
  }

  return createTheme({
    typography: {
      fontFamily: [
        'Montserrat',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
      ].join(','),
      caption: {
        fontWeight: 400,
        fontSize: 14,
      },
      fontWeightRegular: 400,
      fontWeightBold: 600,
      h1: {
        fontWeight: 600,
        fontSize: 28,
      },
      h2: {
        fontWeight: 600,
        fontSize: 23,
      },
      h3: {
        fontWeight: 600,
        fontSize: 20,
      },
      h4: {
        fontWeight: 600,
        fontSize: 18,
      },
      h5: {
        fontWeight: 600,
        fontSize: 18,
      },
      h6: {
        fontWeight: 600,
        fontSize: 16,
      },
      body1: {
        fontWeight: 500,
        fontSize: 16,
      },
      body2: {
        fontWeight: 500,
        fontSize: 16,
      },
    },
    components: {
      MuiAppBar: {
        styleOverrides: {
          root: {
            background: background,
            boxShadow: `3px 3px 6px rgba(0, 0, 0, 0.3), -3px -3px 6px rgba(180, 180, 180, 0.2)`,
          },
        },
      },
      MuiSlider: {
        styleOverrides: {
          track: {
            opacity: '100%',
            marginTop: -1,
            height: 3,
            borderRadius: 2,
          },
          thumb: {
            height: 15,
            width: 15,
          },
        },
      },
      MuiStepConnector: {
        styleOverrides: {
          line: {
            borderColor: disabledText,
          },
        },
      },
      MuiStepIcon: {
        styleOverrides: {
          root: {
            boxShadow: `4px 4px 8px rgba(0, 0, 0, ${darkMode ? 0.3 : 0.4}), -4px -4px 8px rgba(255, 255, 255, ${
              darkMode ? 0.07 : 0.6
            })`,
            borderRadius: '50%',
            color: shadeHexColor(background, darkMode ? 0.1 : -0.5),
            '&.Mui-active': {
              color: shadeHexColor(primary, -0.2),
            },
            '&.Mui-completed': {
              color: shadeHexColor(primary, -0.2),
              borderRadius: '50%',
              background: 'white',
            },
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            backgroundColor: shadeHexColor(background, darkMode ? 0.05 : 0.2),
            boxShadow: `3px 3px 6px rgba(0, 0, 0, 0.25), -3px -3px 6px rgba(255, 255, 255, ${darkMode ? 0.06 : 0.3})`,
            padding: 8,
            '&:hover': {
              backgroundColor: shadeHexColor(background, 0.05),
            },
            '&:active': {
              boxShadow: `inset 6px 6px 12px rgba(0, 0, 0, 0.5), inset -6px -6px 12px rgba(180, 180, 180, 0.4)`,
            },
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            boxShadow: `5px 5px 25px 2px rgba(0,0,0,0.4)`,
            backgroundColor: shadeHexColor(background, 0.3),
            color: primaryText,
          },
        },
      },
      MuiFab: {
        styleOverrides: {
          root: {
            boxShadow: `6px 6px 12px rgba(0, 0, 0, ${darkMode ? 0.25 : 0.4}), -6px -6px 12px rgba(255, 255, 255, ${
              darkMode ? 0.1 : 0.7
            })`,
            '&:active': {
              boxShadow: `inset 6px 6px 12px rgba(0, 0, 0, 0.5), inset -6px -6px 12px rgba(180, 180, 180, 0.4)`,
            },
          },
          primary: {
            background: `linear-gradient(145deg, ${primary}, ${shadeHexColor(primary, -0.4)})`,
            '&:hover': {
              background: `linear-gradient(145deg, ${shadeHexColor(primary, -0.15)}, ${shadeHexColor(primary, -0.45)})`,
            },
            '&:active': {
              background: primary,
            },
            '&.Mui-disabled': {
              background: shadeHexColor(background, darkMode ? 0.2 : -0.2),
            },
          },
          secondary: {
            background: `linear-gradient(145deg, ${secondary}, ${shadeHexColor(secondary, -0.4)})`,
            '&:hover': {
              background: `linear-gradient(145deg, ${shadeHexColor(secondary, -0.15)}, ${shadeHexColor(secondary, -0.45)})`,
            },
            '&:active': {
              background: secondary,
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: '20px',
            textTransform: 'none',
            fontWeight: 600,
            transition: 'all 0.2s ease-in-out',
          },
          contained: {
            background: `linear-gradient(145deg, ${primary}, ${shadeHexColor(primary, -0.2)})`,
            boxShadow: `5px 5px 10px rgba(0, 0, 0, ${darkMode ? 0.2 : 0.4}), -5px -5px 10px rgba(255, 255, 255, ${
              darkMode ? 0.1 : 0.7
            })`,
            '&:hover': {
              background: `linear-gradient(145deg, ${shadeHexColor(primary, -0.15)}, ${shadeHexColor(primary, -0.45)})`,
              boxShadow: `5px 5px 10px rgba(0, 0, 0, ${darkMode ? 0.2 : 0.4}), -5px -5px 10px rgba(255, 255, 255, ${
                darkMode ? 0.1 : 0.7
              })`,
            },
            '&:active': {
              background: primary,
              boxShadow: `inset 5px 5px 10px rgba(0, 0, 0, 0.5), inset -5px -5px 10px rgba(180, 180, 180, 0.4)`,
            },
          },
        },
      },
      MuiTable: {
        styleOverrides: {
          root: {
            borderRadius: '7px',
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          rounded: {
            background: `linear-gradient(145deg, ${shadeHexColor(background, darkMode ? 0.1 : 0.1)}, ${shadeHexColor(
              background,
              darkMode ? 0 : 0.2
            )})`,
            borderRadius: '20px',
          }
        },
      },
      MuiTableSortLabel: {
        styleOverrides: {
          root: {
            '&:hover': {
              color: '#FFFFFF',
            },
            '&.Mui-active': {
              color: '#FFFFFF',
            }
          },
          icon: {
            color: '#FFFFFF !important',
          }
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            cursor: 'pointer',
            textDecoration: 'none',
            transition: 'color 0.2s ease-in-out',
            '&:hover': {
              color: shadeHexColor(primary, 0.2),
            },
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            borderRadius: 16,
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.05)',
            transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
            '&:hover': {
              transform: 'translateY(-2px)',
              boxShadow: '0 6px 16px rgba(0, 0, 0, 0.1)',
            },
          },
        },
      },
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            background: darkMode
              ? `linear-gradient(145deg, ${background}, ${shadeHexColor(background, -0.57)})`
              : `linear-gradient(145deg, ${background}, ${shadeHexColor(background, -0.1)})`,
            backgroundAttachment: 'fixed', // Prevents gradient from repeating
          },
        },
      },
    },
    palette: {
      mode: darkMode ? 'dark' : 'light',
      background: {
        default: background,
        paper: paperBackground,
      },
      primary: {
        main: primary,
        light: shadeHexColor(primary, 0.2),
        dark: shadeHexColor(primary, -0.2),
        contrastText: primaryContrastText,
      },
      secondary: {
        main: secondary,
        contrastText: secondaryContrastText,
      },
      text: {
        primary: primaryText,
        secondary: secondaryText,
        disabled: disabledText,
      },
    },
    shape: {
      borderRadius: 8,
    },
    spacing: (factor) => `${0.25 * factor}rem`,
  });
}

export default theme;