import { useState, useEffect, useContext } from "react";
import {
  Chip,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Checkbox,
  ListItemText,
  Box,
  Typography,
  Grid
} from "@mui/material";
import Alert from '@mui/material/Alert';
import ConfigInput from "./ConfigInput";
import {storeConfig, fetchFinetuneConfig, getVersionIdByNumber, getBestFinetune, isStringInLatestVersionStatus} from "../utils/versionManagement";
import { apiUrl } from "../secrets";
import FloatingButton from "./UIElements/FloatingButton";
import { verifyService } from "../utils/serviceManagement";
import { getUserSettings } from "../utils/userManagement";
import { useNavigate } from "react-router-dom";
import getTokens from '../utils/auth';
import { AppContext } from "../AppContext";


const url = apiUrl + '/' + localStorage.getItem('userId');

function containsAny(str, substrings) {
  for (var i = 0; i < substrings.length; i++) {
     var substring = substrings[i];
     if (str.indexOf(substring) !== - 1) {
       return true;
     }
  }
  return false; 
}

const FineTuneConfiguration = ({ onBack, onNext, subscriptionPlan, setConfig,projectId, projectName,
    version, versionId, setFirebaseVersionId, type, functionCalling, setType, setFunctionCalling,
    multiModal, modalities
  }) => {
  const [finetuneType, setFinetuneType] = useState(version > 1 ? "continuous" : "new");
  const [modelList, setModelList] = useState([]);
  const [configNewModelList, setConfigModelList] = useState(['gpt-3.5-turbo-0125']);
  const [runCount, setRunCount] = useState(1);
  const [sweepMethod, setSweepMethod] = useState("grid");
  const [datasetSizePercentageConfig, setDatasetSizePercentageConfig] = useState({ type: "", values: [] });
  const [batchSizeConfig, setBatchSizeConfig] = useState({ type: "chip", values: [] });
  const [epochConfig, setEpochConfig] = useState({ type: "chip", values: [] });
  const [learningRateConfig, setLearningRateConfig] = useState({ type: "chip", values: [] });
  const [promptLossWeightConfig, setPromptLossWeightConfig] = useState({ type: "chip", values: [] });
  const [rankConfig, setRankConfig] = useState({ type: "chip", values: [] });
  const [ErrorMessage, setErrorMessage] = useState("");
  const [previousVersionBestModel, setPreviousVersionBestModel] = useState("");
  const [systemMessage, setSystemMessage] = useState("");
  const { loading, setLoading } = useContext(AppContext);
  const [snackBar, setSnackBar] = useState({ open: false, message: '', severity: 'success' });

  const navigate = useNavigate();

  const openAIVisionModels = [
    { value: "gpt-4o-mini-2024-07-18", name: "OpenAI: GPT-4o Mini" },
    { value: "gpt-4o-2024-08-06", name: "OpenAI: GPT-4o" },
  ]

  const openAIChatModels = [
    { value: "gpt-3.5-turbo-0125", name: "OpenAI: GPT-3.5 Turbo" },
  ].concat(openAIVisionModels);

  const openAIModels = openAIChatModels.concat([
    { value: "davinci-002", name: "OpenAI: Davinci-002" },
    { value: "babbage-002", name: "OpenAI: Babbage-002" },
  ]);
  const openai_models = openAIModels.map(model => model.value);

  const llamasExchangeInstructModels = [
    // { value: "llamas-exchange:meta-llama/Meta-Llama-3.1-70B-Instruct", name: "Llamas Exchange: Llama-3.1 70B Instruct" },
    { value: "llamas-exchange:meta-llama/Meta-Llama-3.1-8B-Instruct", name: "Llamas Exchange: Llama-3.1 8B Instruct" },
  ]
  const llamasExchangeBaseModels = [
    // { value: "llamas-exchange:meta-llama/Meta-Llama-3.1-70B", name: "Llamas Exchange: Llama-3.1 70B" },
    { value: "llamas-exchange:meta-llama/Meta-Llama-3.1-8B", name: "Llamas Exchange: Llama-3.1 8B" },
  ]
  const llamasExchangeModels = llamasExchangeInstructModels.concat(llamasExchangeBaseModels);
  const llamas_exchange_models = llamasExchangeModels.map(model => model.value);

  const googleAIStudioModels = [
    { value: "models/gemini-1.0-pro-001", name: "Google AI Studio: Gemini 1.0 Pro 001" },
    { value: "models/gemini-1.5-flash-001-tuning", name: "Google AI Studio: Gemini 1.5 Flash" },
  ];

  const geminiModels = [
    { value: "gemini-1.5-pro-002", name: "Google Cloud: Gemini 1.5 Pro 002" },
    { value: "gemini-1.5-flash-002", name: "Google Cloud: Gemini 1.5 Flash 002" },
  ];

  const google_ai_studio_models = googleAIStudioModels.map(model => model.value);
  const gemini_models = geminiModels.map(model => model.value);
  const google_models = google_ai_studio_models.concat(gemini_models);
    
  const allModels = openAIModels.concat(llamasExchangeModels).concat([
    { value: "command-r", name: "Cohere: Command R" },
  ]).concat(googleAIStudioModels).concat(geminiModels);

  const chatModels = openAIChatModels.concat(llamasExchangeInstructModels)
    .concat([{ value: "command-r", name: "Cohere: Command R" },
    ]).concat(geminiModels)
  const chat_models = chatModels.map(model => model.value);

  const functionCallingModels = openAIChatModels;
  const visionModels = openAIVisionModels.concat(geminiModels);
  const multiModalModels = geminiModels;

  const prompt_loss_supported_models = [];
  const rank_supported_models = llamas_exchange_models.concat(gemini_models).concat(['command-r'])
  const lr_configuration_supported_models = openai_models.concat(llamas_exchange_models)
      .concat(['command-r']).concat(google_ai_studio_models).concat(gemini_models);
  const batch_size_supported_models = openai_models.concat(llamas_exchange_models)
      .concat(['command-r']).concat(google_ai_studio_models);

  const [models, setModels] = useState(allModels);

  let subscriptionAlert = "";
  if (subscriptionPlan === "free") {
    subscriptionAlert = "Upgrade to Starter or Pro plan to unlock more LLMOps features and finetune LLMs from Vertex AI, Google AI Studio and Cohere";
  }
  else if (subscriptionPlan === "starter") {
    subscriptionAlert = "Upgrade to Pro plan to unlock hyperparameter tuning";
  }

  useEffect(() => {
    setLoading(true);

    //TODO: Separete the config store of type and functionCalling

    const initializeFinetuneConfig = async () => {
      let config = null;
      let configType = null;
      if (versionId) {
        config = await fetchFinetuneConfig(projectId, versionId);
      }
      else {
        const versionId = await getVersionIdByNumber(projectId, version);
        setFirebaseVersionId(versionId);
        config = await fetchFinetuneConfig(projectId, versionId);
      }
      if (config) {
        setFinetuneType(config.finetuneType);
        configType = config.finetuneType;
        setModelList(config.modelList);
        if (config.finetuneType === "new") {
          setConfigModelList(config.modelList);
        }
        setRunCount(config.runCount);
        setType(config.type);
        setFunctionCalling(config.functionCalling);
        setSweepMethod(config.sweepMethod);
        setDatasetSizePercentageConfig(config.datasetSizePercentageConfig);
        setBatchSizeConfig(config.batchSizeConfig);
        setEpochConfig(config.epochConfig);
        setLearningRateConfig(config.learningRateConfig);
        setRankConfig(config.rankConfig);
        setPromptLossWeightConfig(config.promptLossWeightConfig);
        setSystemMessage(config.systemMessage);
      }
      else {
        if (version > 1) {
          const previousVersionId = await getVersionIdByNumber(projectId, version - 1);
          const config = await fetchFinetuneConfig(projectId, previousVersionId);
          if (config) {
            // store new model list for switching between new and continuous finetuning
            if (config.finetuneType === "new") {
              setConfigModelList(config.modelList);
            }

            // For version numbers greater than 2, keep previous finetune type
            // But for version number 2, change from "new" to "continuous"
            if (version !== 2) {
              setFinetuneType(config.finetuneType);
              configType = config.finetuneType;
            }
            else {
              setFinetuneType("continuous");
              configType = "continuous";
            }

            //if previous finetune type is "new", keep the same base model list
            if (configType === "new") {
              setModelList(config.modelList);
            }

            setRunCount(config.runCount);
            setType(config.type);
            setFunctionCalling(config.functionCalling);
            setSweepMethod(config.sweepMethod);
            setDatasetSizePercentageConfig(config.datasetSizePercentageConfig);
            setBatchSizeConfig(config.batchSizeConfig);
            setEpochConfig(config.epochConfig);
            setLearningRateConfig(config.learningRateConfig);
            setRankConfig(config.rankConfig);
            setPromptLossWeightConfig(config.promptLossWeightConfig);
            setSystemMessage(config.systemMessage);
          }
        }
      }

      if (version > 1) {
        const previousVersionId = await getVersionIdByNumber(projectId, version - 1);

        const model = await getBestFinetune(navigate, projectId, previousVersionId, projectName, version - 1);
        if (model) {
          setPreviousVersionBestModel(model);
          if (configType === "continuous") {
            setModelList([model]);
          }
        }
        else {
          setErrorMessage("Error fetching best finetuned model from previous version. Use New Finetune method or Please contact support");
        }
      }
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    };

    initializeFinetuneConfig(); 
  }, []);

  useEffect(() => {
    if(type === 'chat'){
      if(functionCalling){
        setModels(functionCallingModels);
      }
      else{
        if (multiModal) {
          const visionOnly = modalities.includes("vision") && modalities.length === 1;
          const notVisionOnly = modalities.includes("audio") || modalities.includes("document");
          if(subscriptionPlan === 'free'){
            if (visionOnly){
              setModels(openAIVisionModels);
            }
            else{
              setModels([
                { value: "none", name: "Multi Modal Models are not available in Free Plan" }
              ]);
            }
          }
          else{
            if (visionOnly){
              setModels(visionModels);
            }
            else if (notVisionOnly){
              setModels(multiModalModels);
            }
          }
        }
        else{
          if(subscriptionPlan === 'free'){
            setModels(openAIChatModels.concat(llamasExchangeInstructModels));
          }
          else{
            setModels(chatModels);
          }
        }
      }
    }
    else{
      if (multiModal){
        const visionOnly = modalities.includes("vision") && modalities.length === 1;
        const notVisionOnly = modalities.includes("audio") || modalities.includes("document");
        if(subscriptionPlan === 'free'){
          if (visionOnly){
            setModels(openAIVisionModels);
          }
          else{
            setModels([
              { value: "none", name: "Multi Modal Models are not available in Free Plan" }
            ]);
          }
        }
        else{
          if (visionOnly){
            setModels(visionModels);
          }
          else if (notVisionOnly){
            setModels(multiModalModels);
          }
        }
      }
      else{
        setModels(openAIModels.concat(llamasExchangeModels));
      }
    }
  }, [type]);

  const handleFinetuneTypeChange = (type) => {
    setFinetuneType(type);
    if (type === "continuous") {
      setModelList([previousVersionBestModel]);
    }
    else {
      setModelList(configNewModelList);
    }
  };

  const handleModelListChange = (e) => {
    const newModelList = [...modelList];
    if (e.target.checked) {
      newModelList.push(e.target.name);
    } else {
      const index = newModelList.indexOf(e.target.name);
      newModelList.splice(index, 1);
    }
    setModelList(newModelList);
    setRunCount(newModelList.length);
  };

  const getConfigValues = (config) => {
    if (config.type === "chip") {
      if (config.values.length === 0) {
        return null;
      }
      return { "values": config.values };
    } else {
      return config.values;
    }
  }

  const calculateEstimateCost = async () => {
    let newModel = false;
    if (finetuneType === "new") {
      newModel = true;
    }

    let errorMessageCalled = false;

    isStringInLatestVersionStatus(projectId,"dataset created").then((datasetCreated)=>{
      if (!datasetCreated) {
        isStringInLatestVersionStatus(projectId, "failed to create dataset").then((datasetCreationFailed) => {
          if (datasetCreationFailed) {
            setErrorMessage("dataset creation failed for this version. delete the current version and retry from beginning. Contact support if needed");
            errorMessageCalled = true;
          }
          else {
            setErrorMessage("dataset is getting created. Please wait for some time and retry");
            errorMessageCalled = true;
          }
        });
      }
    });

    if (errorMessageCalled) {
      return false;
    }

    var epochConfigValue = getConfigValues(epochConfig);
    return new Promise((resolve) => {
      verifyService(navigate).then((canContinue) => {
        if (canContinue) {
          getTokens().then((headers) => {
            fetch(`${url}/getEstimatedCost`, {
              "method": "POST",
              "body": JSON.stringify({
                new_model: newModel,
                models: modelList,
                run_count: runCount,
                epoch_configuration: epochConfigValue,
                project: projectName,
                type: type,
              }),
              headers: {
                ...{
                  "Content-Type": "application/json",
                }, ...headers
              },
            })
              .then((res) => {
                if (res.status === 200) {
                  return res.json();
                }
                else {
                  setErrorMessage("Error calculating estimated finetuning cost for running this sweep (hyperparamter tuning)");
                }
              })
              .then((data) => {
                if (data['error']) {
                  setErrorMessage(data['error']);
                  resolve(false);
                }
                else if (data['cost'] || data["cost"] === 0.0) {
                  alert(`Total estimated finetuning cost for running this sweep (hyperparamter tuning) is ${data['cost']}`);
                  resolve(true);
                }
                else {
                  setErrorMessage("Error calculating estimated finetuning cost for running this sweep (hyperparamter tuning)");
                  resolve(false);
                }
              })
              .catch((err) => {
                setErrorMessage("Error calculating estimated finetuning cost for running this sweep (hyperparamter tuning)");
                resolve(false);
              }
              );
          });
        }
      });
    });
  }

  const handleCreateFineTuneConfiguration = async () => {
    setLoading(true)
    try {
      if (modelList.length === 0) {
        setErrorMessage("Please select at least one model");
        return;
      }
      
      // TODO - Gemini only accepts [1,4,8,16,32]
      // Llamas Exchange accepts [8, 16, 32, 64, 128, 256, 512]
      // Handle this in complex hyperparameter tuning
      const allowedRankValues = [1, 4, 8, 16, 32, 64, 128, 256, 512];
      if(rankConfig.values.length > 0){
        for (let i = 0; i < rankConfig.values.length; i++) {
          if (!allowedRankValues.includes(rankConfig.values[i])) {
            setErrorMessage(`Rank value ${rankConfig.values[i]} is not allowed. Provide a power of 2 `);
            return;
          }
        }
      }

      if (!containsAny(previousVersionBestModel, openai_models) && finetuneType === "continuous") {
        setErrorMessage("Previous version's best finetune is not created in OpenAI. You can not choose continuous finetuning");
        return;
      }

      // if (containsAny(previousVersionBestModel, ["gpt-3.5-turbo", "babbage-002", "davinci-002"]) && finetuneType === "continuous") {
      //   setErrorMessage("You can not choose continuous finetuning for OpenAI's new version of finetunable models");
      //   return;
      // }

      await getUserSettings(localStorage.getItem('userId')).then((settings) => {
        if (settings) {
          const cohereKey = settings.cohereKey || null;
          const llamasExchangeKey = settings.llamasExchangeKey || null;

          let keysList = [];
          if (modelList.includes('command-r')) {
            if (!cohereKey) {
              keysList.push("Cohere API Key")
            }
          }

          if (modelList.some((model) => llamas_exchange_models.includes(model))) {
            if (!llamasExchangeKey) {
              keysList.push("Llamas Exchange API Key")
            }
          }

          if (modelList.some((model) => google_models.includes(model))) {
            if (!llamasExchangeKey) {
              keysList.push("GCP Service Account Details")
            }
          }

          if(keysList.length > 0){
            let alert_string = "Please add ";
            for(let key in keysList){
              alert_string += keysList[key] + ', ';
            }
            alert(alert_string.slice(0,-2));
            navigate(`/userSettings`);
            return null;
          }
        }
        else {
          alert("Please add required API keys in settings");
          navigate(`/userSettings`);
          return null;
        }

      });

      const canGoNext = await calculateEstimateCost();
      if (!canGoNext) {
        return;
      }

      let ftConfig = {}

      if (finetuneType === "new") {
        ftConfig["new_model"] = true;
      } else {
        ftConfig["new_model"] = false;
      }

      if (version > 1) {
        ftConfig["continuous"] = true;
      }

      ftConfig["models"] = modelList;

      // console.log(runCount)
      // console.log(typeof runCount);

      ftConfig["run_count"] = runCount;

      ftConfig["sweep_method"] = sweepMethod;

      const datasetSizePercentageValue = getConfigValues(datasetSizePercentageConfig);
      if (datasetSizePercentageValue != null) {
        ftConfig["dataset_size_percentages"] = datasetSizePercentageValue;
      }


      var batchSizeConfigValue = getConfigValues(batchSizeConfig);
      if (batchSizeConfigValue != null) {
        ftConfig["batch_size_configuration"] = batchSizeConfigValue;
      }

      var epochConfigValue = getConfigValues(epochConfig);
      if (epochConfigValue != null) {
        ftConfig["epoch_configuration"] = epochConfigValue;
      }

      var learningRateConfigValue = getConfigValues(learningRateConfig);
      if (learningRateConfigValue != null) {
        ftConfig["lr_configuration"] = learningRateConfigValue;
      }

      var rankConfigValue = getConfigValues(rankConfig);
      if (rankConfigValue != null) {
        ftConfig["rank_configuration"] = rankConfigValue;
      }

      var promptLossWeightConfigValue = getConfigValues(promptLossWeightConfig);
      if (promptLossWeightConfigValue != null) {
        ftConfig["prompt_loss_weight_configuration"] = promptLossWeightConfigValue;
      }

      if (systemMessage !== "") {
        ftConfig["system_message"] = systemMessage;
      }

      if (multiModal){
        ftConfig["multimodal"] = true;
      }

      const createConfigObject = () => {
        return {
          finetuneType: finetuneType,
          modelList: modelList,
          runCount: runCount,
          sweepMethod: sweepMethod,
          datasetSizePercentageConfig: datasetSizePercentageConfig,
          batchSizeConfig: batchSizeConfig,
          epochConfig: epochConfig,
          learningRateConfig: learningRateConfig,
          rankConfig: rankConfig,
          promptLossWeightConfig: promptLossWeightConfig,
          systemMessage: systemMessage,
          type: type,
          functionCalling: functionCalling
        };
      };

      const config = createConfigObject();

      if (versionId) {
        storeConfig(projectId, versionId, config, "finetune");
      }
      else {
        getVersionIdByNumber(projectId, version).then((versionId) => {
          storeConfig(projectId, versionId, config, "finetune");
        });
      }

      setConfig(ftConfig);

      onNext();
      setLoading(false)
      setSnackBar({ open: true, message: 'Started finetune configuration', severity: 'success' })
    } catch (err) {
      console.log(err);
      setLoading(false)
      setSnackBar({ open: true, message: 'Failed', severity: 'error' })

    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <Box display={"flex"} flexDirection={"column"} gap={3} py={3}>
      {subscriptionPlan !== "pro" && (<Alert severity="info">{subscriptionAlert}</Alert>)}
        <Box pb={2} className="form-help-text" sx={{ display: { xs: 'none', md: 'block' } }}>
          Docs: <a href="https://www.easyllm.tech/docs/finetune-configuration.html" target="_blank" className="form-help-hypertext" rel="noreferrer"> Configuration </a> and <a href="https://www.easyllm.tech/docs/blog/hyperparameter-tuning-for-finetuning-large-language-models.html" className="form-help-hypertext" target="_blank" rel="noreferrer">Tutorial</a>
        </Box>
        <Grid container spacing={2}>
          {version > 1 && (
            <Grid item xs={12} sm={3}>
              <Typography variant="subtitle1">Finetune Type</Typography>
              <FormControl fullWidth>
                <Select
                  size="small"
                  labelId="finetune-type-label"
                  id="finetune-type"
                  value={finetuneType}
                  onChange={(e) => handleFinetuneTypeChange(e.target.value)}
                >
                  <MenuItem value="new">New Finetune</MenuItem>
                  <MenuItem value="continuous">Continuous Finetuning</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          )}
          {(version > 1 && finetuneType === 'new') || version === 1 ? (
            <Grid item xs={12} sm={4}>
              <Typography variant="subtitle1">Model List</Typography>
              <FormControl fullWidth>
                <Select
                  size="small"
                  labelId="model-list-label"
                  id="model-list"
                  multiple
                  displayEmpty={true}
                  value={modelList}
                  // onChange={(e) => handleModelListChange(e)}
                  renderValue={(selected) => (
                    <Box display="flex" flexWrap="wrap" gap={1}>
                      {selected.map((model) => (
                        <Chip key={model} label={model} size="small" />
                      ))}
                      {!selected.length && (
                        <Chip label="Select Models" size="small" />
                      )}
                    </Box>
                  )}
                >
                {
                  models.map((model) => (
                    <MenuItem key={model.value} value={model.value}>
                      <Checkbox
                        checked={modelList.includes(model.value)}
                        onChange={handleModelListChange}
                        name={model.value}
                      />
                      <ListItemText primary={model.name} />
                    </MenuItem>
                  ))
                }
                </Select>
              </FormControl>
            </Grid>
          ) : (
            version > 1 && finetuneType === 'continuous' && (
              <Grid item xs={12} sm={4}>
                <Typography variant="subtitle1">Model List</Typography>
                <FormControl fullWidth>
                  <Select
                    size="small"
                    labelId="model-list-label"
                    id="model-list"
                    multiple
                    displayEmpty={true}
                    value={modelList}
                    // onChange={(e) => handleModelListChange(e)}
                    renderValue={(selected) => (
                      <Box display="flex" flexWrap="wrap" gap={1}>
                        {selected.map((model) => (
                          <Chip key={model} label={model} size="small" />
                        ))}
                        {!selected.length && (
                          <Chip label="Select Models" size="small" />
                        )}
                      </Box>
                    )}
                  >
                    <MenuItem value={previousVersionBestModel}>
                      <Checkbox
                        checked={modelList.includes(previousVersionBestModel)}
                        onChange={handleModelListChange}
                        name={previousVersionBestModel}
                      />
                      <ListItemText primary={previousVersionBestModel} />
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            )
          )}
          <Grid item xs={12} sm={2}>
            <Typography variant="subtitle1">Run Count</Typography>
            <TextField
              size="small"
              type="number"
              value={runCount}
              onChange={(e) => setRunCount(parseInt(e.target.value))}
              fullWidth
            />
          </Grid>
          {modelList.some((model) => chat_models.includes(model)) && (
            <>
              <Grid item xs={12} sm={4}>
                <Typography variant="subtitle1">System Message</Typography>
                <TextField
                  size="small"
                  value={systemMessage}
                  onChange={(e) => setSystemMessage(e.target.value)}
                  fullWidth
                />
              </Grid>
            </>
          )}
          {subscriptionPlan === "pro" && (
            <>
              <Grid item xs={12} sm={2}>
                <Typography variant="subtitle1">Sweep Method</Typography>
                <FormControl fullWidth>
                  <Select
                    size="small"
                    labelId="sweep-method-label"
                    id="sweep-method"
                    value={sweepMethod}
                    onChange={(e) => setSweepMethod(e.target.value)}
                  >
                    <MenuItem value="bayes">Bayesian Sweep</MenuItem>
                    <MenuItem value="random">Random Sweep</MenuItem>
                    <MenuItem value="grid">Grid Sweep</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </>
          )}
        </Grid>

        {subscriptionPlan === "pro" && (
          <>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <ConfigInput
                  config={epochConfig}
                  setConfig={setEpochConfig}
                  name="Epochs Configuration"
                  inputKey="epochs"
                  maxListLength={10}
                  allowedRange={{ min: 1, max: 20 }}
                  helperText="Enter a value between 1 to 20"
                />
              </Grid>
              {/* Show only if model list contains any model from lr_configuration_supported_models */}
              {modelList.some((model) => lr_configuration_supported_models.includes(model)) && (
              <Grid item xs={12} sm={6}>
                <ConfigInput
                  config={learningRateConfig}
                  setConfig={setLearningRateConfig}
                  name="Learning Rate Configuration"
                  inputKey="learning-rate"
                  maxListLength={10}
                  allowedRange={{ min: 0.01, max: 1.0 }}
                  helperText="Enter a value between 0.01 to 1.0"
                />
              </Grid>
              )}
              {/* Show only if model list contains any model from batch_size_supported_models */}
              {modelList.some((model) => batch_size_supported_models.includes(model)) && (
              <Grid item xs={12} sm={6}>
                <ConfigInput
                  config={batchSizeConfig}
                  setConfig={setBatchSizeConfig}
                  name="Batch Size Configuration"
                  inputKey="batch-size"
                  maxListLength={10}
                  allowedRange={{ min: 16, max: 512 }}
                  helperText="Enter a value between 16 to 512"
                />
              </Grid>
              )}
              {/* Show only if model list contains any model from rank_supported_models */}
              {modelList.some((model) => rank_supported_models.includes(model)) && (
              <Grid item xs={12} sm={6}>
                <ConfigInput
                  config={rankConfig}
                  setConfig={setRankConfig}
                  name="LoRA Rank Configuration"
                  inputKey="rank"
                  maxListLength={10}
                  allowedRange={{ min: 8, max: 512 }}
                  helperText="Enter a value between 8 to 512. In powers of 2"
                />
              </Grid>
              )}
              <Grid item xs={12} sm={6}>
                <ConfigInput
                  config={datasetSizePercentageConfig}
                  setConfig={setDatasetSizePercentageConfig}
                  name="Dataset Size Percentages"
                  inputKey="dataset-size-percentages"
                  maxListLength={10}
                  allowedRange={{ min: 1, max: 100 }}
                  helperText="Enter a value between 1 to 100"
                />
              </Grid>
              {/* Show only if model list contains any model from  prompt_loss_supported_models */}
              {modelList.some((model) => prompt_loss_supported_models.includes(model)) && (
              <Grid item xs={12} sm={6}>
                <ConfigInput
                  config={promptLossWeightConfig}
                  setConfig={setPromptLossWeightConfig}
                  name="Prompt Loss Weight Configuration"
                  inputKey="prompt-loss-weight"
                  maxListLength={10}
                  allowedRange={{ min: 0.0, max: 1.0 }}
                  helperText="Enter a value between 0.0 to 1.0"
                />
              </Grid>
              )}
            </Grid>
          </>
        )}
        <Box display={"flex"} flexDirection={"row"} gap={5} grid={3}></Box>
        {ErrorMessage && (
          <Alert severity="error">{ErrorMessage}</Alert>
        )}
        {!loading && (<FloatingButton onClick={handleCreateFineTuneConfiguration} />)}
      </Box>
    </>
  );
}

export default FineTuneConfiguration;