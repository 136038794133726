import { useState, useEffect, useContext } from "react";
import { useNavigate } from 'react-router-dom';
import {
    Button,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Box,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Checkbox,
    Typography,
    Card,
    CardContent
} from "@mui/material";
import Alert from '@mui/material/Alert';
import { getFirestore, collection, doc, getDoc, query, orderBy, limit, getDocs, addDoc, updateDoc } from 'firebase/firestore';

import app from '../firebase-app';
import { apiUrl } from "../secrets";
import FloatingButton from './UIElements/FloatingButton';
import DatasetPercentageSlider from "./UIElements/DatasetPercentageSlider";
import { verifyService } from "../utils/serviceManagement";
import getTokens from '../utils/auth';
import { AppContext } from "../AppContext";
import { storeConfig, getCreateDatasetConfigFromFirebase } from "../utils/versionManagement";

const db = getFirestore(app);

const url = apiUrl + '/' + localStorage.getItem('userId');

const CreateDataset = (
    {   
        onNext, project, projectName, setFirebaseVersionId,
        version, setType, setFC, 
        multiModal, setMultiModal,
        modalities, setModalities 
    }) => {
    const [trainFile, setTrainFile] = useState(null);
    const [validationFile, setValidationFile] = useState(null);
    const [testFile, setTestFile] = useState(null);
    const [validationPercentage, setValidationPercentage] = useState(10);
    const [testPercentage, setTestPercentage] = useState(10);
    const [promptEndToken, setPromptEndToken] = useState("\\n###\\n");
    const [completionEndToken, setCompletionEndToken] = useState("\\n");
    const [checkNullValues, setCheckNullValues] = useState(false);
    const [binaryClassification, setBinaryClassification] = useState(false);
    const [chat, setChat] = useState(false);
    const [functionCalling, setFunctionCalling] = useState(false);
    const [classification, setClassification] = useState(false);
    //TODO - Implement number of claasses checking in the backend and store the classes
    const [classificationNCls, setClassificationNCls] = useState(null);
    const [choosedClassification, setChoosedClassification] = useState(false);
    const [choosedChat, setChoosedChat] = useState(false);
    const [choosedFunctionCalling, setChoosedFunctionCalling] = useState(false);
    const [choosedMultiModal, setChoosedMultiModal] = useState(false);
    const [files, setFiles] = useState([]);
    const [imagesFile, setImagesFile] = useState(null);
    const [ErrorMessage, setErrorMsg] = useState("");
    const [fileUploadOpen, setFileUploadOpen] = useState(false);
    const [chooseFileOpen, setChooseFileOpen] = useState(false);
    const [fileUploadError, setFileUploadError] = useState("");
    const [fileSelectError, setFileSelectError] = useState("");
    const [currentType, setCurrentType] = useState("train");
    const [choosedFile, setChoosedFile] = useState("");
    const { loading, setLoading } = useContext(AppContext);

    const navigate = useNavigate();

    // Use the getConfigFromFirebase function in a useEffect hook
    useEffect(() => {
        getCreateDatasetConfigFromFirebase(project, version)
        .then((config) => {
            if (config) {
            setChoosedClassification(config.choosedClassification);
            setChoosedChat(config.choosedChat);
            setChoosedFunctionCalling(config.choosedFunctionCalling);
            setBinaryClassification(config.binaryClassification);
            setClassification(config.classification);
            setCompletionEndToken(config.completionEndToken);
            setPromptEndToken(config.promptEndToken);
            setValidationPercentage(config.validationPercentage);
            setTestPercentage(config.testPercentage);
            setCheckNullValues(config.checkNullValues);
            setClassificationNCls(config.classificationNCls);
            setChat(config.chat);
            setMultiModal(config.multiModal);
            setModalities(config.modalities);
            setFunctionCalling(config.functionCalling);
            }
        });
    }, []);

    // function to handle file selection for train, validation and test datasets
    const handleFileSelect = (type, file) => {
        // console.log(type, file);
        if (file) {
            switch (type) {
                case "train":
                    setTrainFile(file);
                    break;
                case "validation":
                    setValidationFile(file);
                    break;
                case "test":
                    setTestFile(file);
                    break;
                case "images":
                    setImagesFile(file);
                    break;
                default:
                    break;
            }
        }
    };

    const handleFileSelectOpen = (type) => {
        // call api /getFiles/{project} to get list of previouly uploaded files. This api will return
        // {"files" : [*list of files*] }
        // Let user choose one of the files and store it in filename variable for a particular dataset type. If no files are uploaded display "No files uploaded"

        setLoading(true);
        verifyService(navigate).then((canContinue) => {
            // setLoading(false)
            if (canContinue) {
                getTokens().then((headers) => {
                    fetch(`${url}/getFiles/${projectName}`, { headers: headers })
                        .then((response) => {
                            if (!response.ok) {
                                // console.log(response);
                                throw new Error('Network response was not ok');
                            }
                            return response.json();
                        })
                        .then((data) => {
                            if (!data.files) {
                                throw new Error('Invalid JSON data');
                            }
                            // console.log(data);
                            const files = data.files;
                            setFiles(files);

                            if (files.length === 0) {
                                setFileSelectError("You haven't uploaded any files yet. Please use Upload File option")
                            }
                        })
                        .catch((error) => {
                            // console.log(error);
                            // Handle error appropriately, e.g. show an error message to the user
                        });
                });
                setLoading(false)
                setCurrentType(type);
                setChooseFileOpen(true);
            }
            else {
                setLoading(false)
            }

        }).catch((err) => {
            setLoading(false);
        });
        // setLoading(false);
    };

    const handleFileUpload = (file) => {
        const data = {
            project: projectName,
            check_null: checkNullValues,
            binary_classification: binaryClassification,
            classification: classification,
            chat: chat,
            function_calling: functionCalling,
            multimodal: multiModal,
        };

        const formData = new FormData();
        formData.append('file', file);

        const urlParams = new URLSearchParams();
        Object.entries(data).forEach(([key, value]) => urlParams.append(key, value));

        return verifyService(navigate).then((canContinue) => {
            if (canContinue) {
                return getTokens().then((headers) => {
                    const requestOptions = {
                        method: 'POST',
                        body: formData,
                        headers: headers,
                        redirect: 'follow'
                    };

                    return fetch(`${url}/fileUpload?${urlParams.toString()}`, requestOptions)
                        .then(response => {
                            if (!response.ok) {
                                if (response.status === 400) {
                                    return response.json().then(error => {
                                        throw new Error(error.detail || "Bad Request");
                                    });
                                }
                                throw new Error(`Error in File Upload`);
                            }
                            return response.json();
                        })
                        .then(data => {
                            // console.log(data);
                            const filename = data.file_name;
                            const newModalities = data.modalities;
                            handleFileSelect(currentType, filename);
                            if (classification || binaryClassification) {
                                setChoosedClassification(true);
                            }
                            if(chat){
                                setChoosedChat(true);
                            }
                            if(functionCalling){
                                setChoosedFunctionCalling(true);
                            }
                            if(multiModal){
                                setChoosedMultiModal(true);
                                // add only new modalities that aren't already present
                                const uniqueModalities = [...new Set([...modalities, ...newModalities])];
                                setModalities(uniqueModalities);
                            }
                            return true;
                        })
                        .catch(error => {
                            // console.log('error', error);
                            setLoading(false);
                            setFileUploadError(error.message);
                            return false;
                        });
                });
            } else {
                setLoading(false)
            }
        });
    };

    const handleImagesUpload = (file) => {
        const data = {
            project: projectName
        };

        const formData = new FormData();
        formData.append('file', file);

        const urlParams = new URLSearchParams();
        Object.entries(data).forEach(([key, value]) => urlParams.append(key, value));

        return verifyService(navigate).then((canContinue) => {
            if (canContinue) {
                return getTokens().then((headers) => {
                    const requestOptions = {
                        method: 'POST',
                        body: formData,
                        headers: headers,
                        redirect: 'follow'
                    };

                    return fetch(`${url}/multiModalFilesUpload?${urlParams.toString()}`, requestOptions)
                        .then(response => {
                            if (!response.ok) {
                                if (response.status === 400) {
                                    return response.json().then(error => {
                                        throw new Error(error.detail || "Bad Request");
                                    });
                                }
                                throw new Error(`Error in File Upload`);
                            }
                            return response.json();
                        })
                        .then(data => {
                            // console.log(data);
                            const filename = data.file_name;
                            handleFileSelect(currentType, filename);
                            return true;
                        })
                        .catch(error => {
                            // console.log('error', error);
                            setLoading(false);
                            setFileUploadError(error.message);
                            return false;
                        });
                });
            } else {
                setLoading(false)
            }
        });
        
    }

    const handleUploadClick = () => {
        const file = document.getElementById("fileUpload").files[0];
        if (file) {
            // console.log(file);
            setLoading(true);
            let functionToCall;
            if (currentType === "images") {
                functionToCall = handleImagesUpload;
            }
            else {
                functionToCall = handleFileUpload;
            }
            functionToCall(file).then
                (result => {
                    if (result) {
                        // console.log(result);
                        setLoading(false);
                        handleFileUploadClose();
                    }
                })
                .catch((err) => {
                    setLoading(false);
                });

        } else {
            setFileUploadError("Please select a file to upload.");
        }
    };

    const handleFileUploadOpen = (type) => {
        // console.log(type);
        setCurrentType(type);
        setFileUploadOpen(true);
    };

    // function to handle validation percentage input
    const handleValidationPercentageInput = (event) => {
        const percentage = event.target.value;
        setValidationPercentage(percentage);
    };

    // function to handle test percentage input
    const handleTestPercentageInput = (event) => {
        const percentage = event.target.value;
        setTestPercentage(percentage);
    };

    const handleValidationPercentageSlider = (newValue) => {
        setValidationPercentage(newValue);
    };

    const handleTestPercentageSlider = (newValue) => {
        setTestPercentage(newValue);
    };


    // function to handle prompt end token input
    const handlePromptEndTokenInput = (event) => {
        const token = event.target.value;
        setPromptEndToken(token);
    };

    // function to handle completion end token input
    const handleCompletionEndTokenInput = (event) => {
        const token = event.target.value;
        setCompletionEndToken(token);
    };

    // function to handle check for null values checkbox
    const handleCheckNullValues = (event) => {
        const value = event.target.checked;
        setCheckNullValues(value);
    };

    // function to handle binary classification checkbox
    const handleBinaryClassification = (event) => {
        if (choosedClassification) {
            setFileUploadError("Already uploaded a file as classification data");
        }
        else {
            const value = event.target.checked;
            setBinaryClassification(value);
        }
    };

    // function to handle binary classification checkbox
    const handleClassification = (event) => {
        if (choosedClassification) {
            setFileUploadError("Already uploaded a file as classification data");
        }
        else {
            const value = event.target.checked;
            setClassification(value);
        }
    };

    const handleChat = (event) => {
        if (choosedChat) {
            setFileUploadError("Already uploaded a file as chat data");
        }
        else {
            const value = event.target.checked;
            setChat(value);
        }
    };

    const handleFunctionCalling = (event) => {
        if (choosedFunctionCalling) {
            setFileUploadError("Already uploaded a file as chat data with function calling");
        }
        else {
            const value = event.target.checked;
            setFunctionCalling(value);
            if(value){
                setChat(true);
            }
        }
    };

    const handleMultiModal = (event) => {
        if (choosedMultiModal) {
            setFileUploadError("Already uploaded a file for multiModal finetuning");
        }
        else {
            const value = event.target.checked;
            setMultiModal(value);
        }
    };

    const handleFileUploadClose = () => {
        setFileUploadOpen(false);
        setFileUploadError("");
    };

    const handleChooseFileClose = () => {
        setChooseFileOpen(false);
    };

    //Add new version to Firebase
    const addNewVersion = async (projectId, createdBy, type, production) => {
        try {
            const projectRef = doc(db, 'projects', projectId);
            const versionsRef = collection(projectRef, 'versions');

            // Get the latest version number
            const q = query(versionsRef, orderBy('versionNumber', 'desc'), limit(1));
            const snapshot = await getDocs(q);
            let latestVersionNumber = 0;

            if (!snapshot.empty) {
                latestVersionNumber = snapshot.docs[0].data().versionNumber;
            }

            // Increment the version number
            const newVersionNumber = latestVersionNumber + 1;

            // Create a new version entry
            const newVersion = {
                versionNumber: newVersionNumber,
                createdDate: new Date(),
                createdBy,
                type,
                production,
            };

            // Add the new version to the "versions" collection and get the document reference
            const docRef = await addDoc(versionsRef, newVersion);

            // Get the ID of the newly created document
            const newVersionId = docRef.id;

            // Include the ID in the newVersion object
            const newVersionWithId = {
                ...newVersion,
                id: newVersionId,
            };

            return newVersionWithId;
        }
        catch {
            return null;
        }
    };

    const updateStatus = async (projectId, versionId, newStatus) => {
        const versionRef = doc(db, 'projects', projectId, 'versions', versionId);

        // Get the current status array
        const versionDoc = await getDoc(versionRef);
        const currentStatus = versionDoc.data().status || [];

        // Add the new status to the status array
        const updatedStatus = [...currentStatus, newStatus];

        // Update the status field in the version document
        await updateDoc(versionRef, { status: updatedStatus });

        return updatedStatus;
    };

    // function to handle create dataset button click
    const handleCreateDataset = () => {
        setLoading(true);
        verifyService(navigate)
            .then((canContinue) => {
                if (canContinue) {

                    // validate inputs
                    let validationSetPercentageValue = validationPercentage;
                    let testSetPercentageValue = testPercentage;
                    let validationCsvFileValue = validationFile;
                    let testCsvFileValue = testFile;

                    if (
                        validationSetPercentageValue &&
                        (validationSetPercentageValue < 0.1 || validationSetPercentageValue > 50)
                    ) {
                        setLoading(false);
                        setErrorMsg("Validation set percentage must be between 0.1 and 50");
                        return;
                    }

                    if (
                        testSetPercentageValue &&
                        (testSetPercentageValue < 0.1 || testSetPercentageValue > 50)
                    ) {
                        setLoading(false);
                        setErrorMsg("Test set percentage must be between 0.1 and 50");
                        return;
                    }

                    const requestBody = {
                        project: projectName,
                        csv_file: trainFile,
                    };

                    if (!validationCsvFileValue && validationSetPercentageValue) {
                        requestBody.validation_set_percentage = validationSetPercentageValue;
                    } else if (validationCsvFileValue) {
                        requestBody.validation_csv_file = validationCsvFileValue;
                    }

                    if (!testCsvFileValue && testSetPercentageValue) {
                        requestBody.test_set_percentage = testSetPercentageValue;
                    } else if (testCsvFileValue) {
                        requestBody.test_csv_file = testCsvFileValue;
                    }

                    if (promptEndToken) {
                        requestBody.prompt_end_token = promptEndToken;
                    }

                    if (completionEndToken) {
                        requestBody.completion_end_token = completionEndToken;
                    }

                    //if classification is true, set completion end token as empty string
                    if (classification) {
                        requestBody.completion_end_token = "";
                        requestBody.classification = true;
                    }

                    if (chat) {
                        requestBody.type = "chat";
                    }

                    if (multiModal) {
                        requestBody.multimodal = true;
                        if (imagesFile) {
                            requestBody.multimodal_files = imagesFile;
                        }
                    }

                    addNewVersion(project, localStorage.getItem('userId'), null, false).then((version) => {
                        if (version) {
                            setFirebaseVersionId(version.id);
                            updateStatus(project, version.id, 'dataset creation started');

                            requestBody.firebase_project_id = project
                            requestBody.firebase_version_id = version.id

                            getTokens().then((headers) => {
                                fetch(`${url}/createDataset`, {
                                    method: "POST",
                                    headers: {
                                        ...{
                                            "Content-Type": "application/json",
                                        }, ...headers
                                    },
                                    body: JSON.stringify(requestBody),
                                })
                                    .then((response) => {
                                        if (!response.ok) {
                                            const errorMessage = "Failed to create dataset";
                                            // updateStatus(errorMessage);
                                            throw new Error(errorMessage);
                                        }

                                        return response.json();
                                    })
                                    .then((data) => {
                                        const createConfigObject = () => {
                                            return {
                                                binaryClassification: binaryClassification, 
                                                choosedClassification: choosedClassification,
                                                classification: classification,
                                                classificationNCls: classificationNCls,
                                                completionEndToken: completionEndToken,
                                                promptEndToken: promptEndToken,
                                                validationPercentage: validationPercentage,
                                                testPercentage: testPercentage,
                                                checkNullValues: checkNullValues,
                                                chat: chat,
                                                functionCalling: functionCalling,
                                                multiModal: multiModal,
                                                modalities: modalities,
                                                choosedChat: choosedChat,
                                                choosedFunctionCalling: choosedFunctionCalling,
                                            };
                                        };
                        
                                        const config = createConfigObject();
                                        
                                        storeConfig(project, version.id, config, "dataset");
                                        
                                        if(chat){
                                            setType("chat");
                                        }
                                        else{
                                            setType("completion");
                                        }
                                        setFC(functionCalling);
                                        onNext();
                                    })
                                    .catch((error) => {
                                        // console.error(error);
                                        setLoading(false);
                                        setErrorMsg("Failed to create dataset.");
                                    });
                            });
                        }
                        else {
                            setLoading(false);
                            setErrorMsg("Problem in creating new version. Contact support");
                        }
                    });
                } else {
                    setLoading(false);
                }
            })
            .catch((error) => {
                // console.error(error);
                setLoading(false);
            });
    };

    const handleFileSelectChange = (event) => {
        const file = event.target.value;
        setChoosedFile(file);
    };

    const handleSelectClick = () => {
        if (choosedFile) {
            handleFileSelect(currentType, choosedFile);
            handleChooseFileClose();
        } else {
            setFileSelectError("Please select a file.");
        }
    };

    const handleFileSelectClose = () => {
        setChooseFileOpen(false);
    };

    return (
        <>
            <Box className="dataset-upload" py={3}>
                <Box pb={2} className="form-help-text" sx={{ display: { xs: 'none', md: 'block' } }}>
                    Docs : <a href="https://www.easyllm.tech/docs/how-to-prepare-data.html" className="form-help-hypertext" target="_blank" rel="noreferrer">How to prepare data</a> and <a href="https://www.easyllm.tech/docs/create-dataset.html" target="_blank" className="form-help-hypertext" rel="noreferrer">Create Dataset</a>
                </Box>
                <Box pb={3}>
                    <Typography variant="h6">
                        Upload your files to create a new dataset.
                    </Typography>
                </Box>

                <Box display={'flex'} flexDirection={'row'} gap={2.3} flexWrap={'wrap'}>
                    {["train", "validation", "test"].map((type) => (
                        <Card
                            key={type}
                            variant="outlined"
                            sx={{
                                mb: 2,
                                flex: '0 0 32%',
                                borderRadius: '15px',
                                display: 'flex',
                                flexFlow: 'column',
                                '@media (max-width: 1200px)': {
                                    flex: '0 0 100%',
                                },
                            }}
                        >
                            <CardContent>
                                <Box display={"flex"} flexDirection={"row"} gap={2}>
                                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 2 }} >
                                        {type.charAt(0).toUpperCase() + type.slice(1)} File
                                    </Typography>
                                    {/* <CheckCircle sx={{ marginLeft: 'auto' }} color="success" /> */}
                                    {/* Implement the check circle later*/}
                                </Box>
                                <Box display={"flex"} flexDirection={"row"} gap={2}>
                                    <Button
                                        variant="contained"
                                        size="small"
                                        onClick={() => handleFileUploadOpen(type)}
                                    >
                                        Upload File
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        onClick={() => handleFileSelectOpen(type)}
                                    >
                                        Select Existing File
                                    </Button>

                                </Box>
                                <Box className="dataset-upload__form__filename" my={4}>
                                    {type === "train" && trainFile}
                                    {type === "test" && testFile}
                                    {type === "validation" && validationFile}
                                </Box>
                                {/* {(type === "validation" || type === "test") && (<TextField
                                    className="dataset-upload__form__input__percentage"
                                    label="Percentage"
                                    type="number"
                                    value={type === "validation" ? validationPercentage : testPercentage}
                                    onChange={type === "validation" ? handleValidationPercentageInput : handleTestPercentageInput}
                                />
                                )} */}
                                {(type === "validation" || type === "test") && (
                                    <>
                                        <Box display={"flex"} flexDirection={"column"} gap={3}>
                                            <Box display={"flex"} flexDirection={"row"} gap={3} alignItems={'center'}>
                                                <Typography variant="subtitle1">Split Percentage:</Typography>
                                                <TextField
                                                    type="number"
                                                    value={type === 'validation' ? validationPercentage : testPercentage}
                                                    onChange={type === 'validation' ? handleValidationPercentageInput : handleTestPercentageInput}
                                                    inputProps={{
                                                        min: 0,
                                                        max: 25,
                                                    }}
                                                    size="small"
                                                    step={1}
                                                />
                                            </Box>
                                            <Box display="flex" flexDirection="row" alignItems="center" gap={2}>
                                                <DatasetPercentageSlider
                                                    value={type === 'validation' ? validationPercentage : testPercentage}
                                                    onChange={type === 'validation' ? handleValidationPercentageSlider : handleTestPercentageSlider}
                                                    min={0}
                                                    max={25}
                                                    step={1}
                                                />
                                            </Box>
                                        </Box>
                                    </>
                                )}
                                {(multiModal && type === "train") && (
                                    <Box mt={6}>
                                        <Box display={"flex"} flexDirection={"row"} gap={2}>
                                            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 2 }} >
                                                Multi Modal Files - Zip File
                                            </Typography>
                                        </Box>
                                        <Box display={"flex"} flexDirection={"row"} gap={2}>
                                            <Button
                                                variant="contained"
                                                size="small"
                                                onClick={() => handleFileUploadOpen("images")}
                                            >
                                                Upload File
                                            </Button>
                                            <Button
                                                variant="outlined"
                                                size="small"
                                                onClick={() => handleFileSelectOpen("images")}
                                            >
                                                Select Existing File
                                            </Button>
                                        </Box>
                                        <Box className="dataset-upload__form__filename" my={4}>
                                            {imagesFile}
                                        </Box>
                                    </Box>
                                )}
                            </CardContent>
                        </Card>
                    ))}
                </Box>
                <Box display={'flex'} flexDirection={'row'} gap={2} flexWrap={'wrap'}>
                <Box>
                <Box >
                    <Typography variant="h6">
                        Configure End Tokens
                    </Typography>
                </Box>
                <Box display={'flex'} flexDirection={'row'} gap={2} flexWrap={'wrap'}>
                    <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
                        <Box sx={{ padding: '16px' }}>
                            <Typography variant="subtitle1" sx={{ mb: 2 }} >
                                Prompt End Token
                            </Typography>
                            <TextField
                                id="prompt-end-token"
                                className="dataset-upload__form__input__text"
                                type="text"
                                size="small"
                                value={promptEndToken || ""}
                                onChange={handlePromptEndTokenInput}
                                sx={{ width: '100%' }}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Box>
                        <Box sx={{ padding: '16px' }}>
                            <Typography variant="subtitle1" sx={{ mb: 2 }} >
                                Completion End Token
                            </Typography>
                            <TextField
                                id="completion-end-token"
                                className="dataset-upload__form__input__text"
                                type="text"
                                size="small"
                                value={completionEndToken || ""}
                                onChange={handleCompletionEndTokenInput}
                                sx={{ width: '100%' }}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Box>
                    </Box>

                    {ErrorMessage && (
                        <Alert severity="error">{ErrorMessage}</Alert>
                    )}
                </Box>
                </Box>
                </Box>
            </Box>

            {!loading && (<FloatingButton onClick={handleCreateDataset} />)}
            {(!loading && fileUploadOpen) && (
                <Dialog open={fileUploadOpen} onClose={handleFileUploadClose} 
                maxWidth={currentType === "train" ? "md" : "xs"} sx={{ '& .MuiDialog-paper': { width: '60%' } }}>
                    <DialogTitle>Upload File</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Select a file to upload {currentType === "train" ? "and configure options as needed." : ""}
                        </DialogContentText>
                        <Box display={'flex'} flexDirection={'column'} gap={2.3} flexWrap={'wrap'} pt={3}>
                            <input
                                type="file"
                                id="fileUpload"
                                style={{ display: "none" }}
                                accept={ currentType !== "images" ? ".csv,.xlsx,.jsonl" : ".zip"}
                            />
                            <label htmlFor="fileUpload">
                                <Button variant="outlined" component="span">
                                    Select File
                                </Button>
                            </label>
                            {currentType === "train" && (
                            <>
                            <Box display={'flex'} flexDirection={'row'} gap={1}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={checkNullValues}
                                            onChange={handleCheckNullValues}
                                            name="checkNullValues"
                                            color="primary"
                                        />
                                    }
                                    label="Check null values"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={binaryClassification}
                                            onChange={handleBinaryClassification}
                                            name="binaryClassification"
                                            color="primary"
                                        />
                                    }
                                    label="Binary classification"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={classification}
                                            onChange={handleClassification}
                                            name="classification"
                                            color="primary"
                                        />
                                    }
                                    label="Classification"
                                />
                            </Box>
                            <Box display={'flex'} flexDirection={'row'} gap={1}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={chat}
                                            onChange={handleChat}
                                            name="chat"
                                            color="primary"
                                        />
                                    }
                                    label="Chat (Conversational)"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={functionCalling}
                                            onChange={handleFunctionCalling}
                                            name="functionCalling"
                                            color="primary"
                                        />
                                    }
                                    label="Tools (Function Calling)"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={multiModal}
                                            onChange={handleMultiModal}
                                            name="multiModal"
                                            color="primary"
                                        />
                                    }
                                    label="Multi Modal (Image, Audio, Document)"
                                />
                            </Box>
                            </>
                            )}
                            {fileUploadError && (
                                <Alert severity="error">{fileUploadError}</Alert>
                            )}
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleFileUploadClose}>Cancel</Button>
                        <Button variant="contained" onClick={handleUploadClick}>Upload</Button>
                    </DialogActions>
                </Dialog>
            )}
            {chooseFileOpen && (
                <Dialog open={chooseFileOpen} onClose={handleFileSelectClose}>
                    <DialogTitle>Select Existing File</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Select a file from your project to use.
                        </DialogContentText>
                        <Box display={'flex'} flexDirection={'column'} gap={2.3} flexWrap={'wrap'} pt={3}>
                            <FormControl>
                                <InputLabel id="fileSelectLabel">File</InputLabel>
                                <Select
                                    labelId="fileSelectLabel"
                                    id="fileSelect"
                                    value={choosedFile}
                                    onChange={handleFileSelectChange}
                                >
                                    {files.map((option) => (
                                        <MenuItem key={option} value={option}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            {fileSelectError && (
                                <Alert severity="error">{fileSelectError}</Alert>
                            )}
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleFileSelectClose}>Cancel</Button>
                        <Button onClick={handleSelectClick}>Select</Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
};

export default CreateDataset;