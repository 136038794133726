import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Account from './pages/Account';
import ProfilePage from './pages/ProfilePage';
import App from './pages/App';
import Project from './pages/Project';
import CreateFinetune from './pages/CreateFinetune';

function RouterRoutes() {
    return (
        <Router>
            <Routes>
                <Route path='/' element={<App />} />
                <Route path='/account' element={<Account />} />
                <Route path='/userSettings' element={<ProfilePage />} />
                <Route path='/project' element={<Project />} />
                <Route path='/createFinetune' element={<CreateFinetune />} />
                {/* Add more routes for your pages here */}
            </Routes>
        </Router>
    );
}

export default RouterRoutes;