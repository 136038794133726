let env = 'dev'; // 'local', 'dev' or 'production'

let firebaseConfig = {
  apiKey: "AIzaSyAXig_V3rZSFG-8xFseFYx0XtU3izde310",
  authDomain: "dev-easyllm.firebaseapp.com",
  projectId: "dev-easyllm",
  storageBucket: "dev-easyllm.appspot.com",
  messagingSenderId: "413641571157",
  appId: "1:413641571157:web:2dd85ffafcfb988d07cddc",
  measurementId: "G-42ME028SS7"
};

let apiUrl = "http://127.0.0.1:8000";
let serviceUrl = "http://127.0.0.1:8080";
let reCAPTCHASecretKey = '6LduzAgkAAAAAMN7_SDCUeM-EzpcgpdvRpBVJ0lA';
if (env === "dev"){
  apiUrl = "https://dev-api.easyllm.tech";
  serviceUrl = "https://dev-api-service-management-apwpc6wywa-uc.a.run.app";
}

// const pricingTableId= "prctbl_1MMCU3JE261KommP3Cp9zuwo";
// const stripePublishableKey = "pk_test_51MLluXJE261KommP7CrbcjQTRxXoMOSy2vC8GYuC30evTpPj5RrIpn5AKFg8qkiXYI1kDja8XEgs5mXw2Cx0MpHq00sap1UW0C";
// const stripeStatusApiURL = "https://dev-stripe-status-apwpc6wywa-uc.a.run.app";
// const stripeCustomerPortalUrl ="https://billing.stripe.com/p/login/test_cN28za985cdi33W7ss";
// const starterProducts = ["prod_N6OTSO1Gs7MUvs", "prod_OGFQ06KN3rO66w"];
// const proProducts = ["prod_N6P2PTmw4CaAS6", "prod_OGFXzqf7u8t0Se"];

let subscriptionStatusApiURL = "https://us-central1-easyllm.cloudfunctions.net/dev-lemon-status";
let subscriptionCustomerPortalUrl ="https://easyllm.lemonsqueezy.com/billing";
let starterProducts = [129269, 129279];
let proProducts = [128163, 129277];
let lemonCheckOutId = "0e7d3914-6393-430e-a6a4-d1f2ac6ad22c";

if (env === "production"){
firebaseConfig = {
  apiKey: "AIzaSyB9JOKIzwryt3s9sszJPLxWQUMH7qAdJqc",
  authDomain: "easyllm.firebaseapp.com",
  projectId: "easyllm",
  storageBucket: "easyllm.appspot.com",
  messagingSenderId: "87895028146",
  appId: "1:87895028146:web:9ba7d8f0a6c7cbc5ba34ab",
  measurementId: "G-7RF01PPLP1"
};

apiUrl = "https://api.easyllm.tech";
serviceUrl = "https://api-service-management-apwpc6wywa-uc.a.run.app";
reCAPTCHASecretKey = '6LduzAgkAAAAAMN7_SDCUeM-EzpcgpdvRpBVJ0lA';

// const pricingTableId= "prctbl_1Na1SqJE261KommPsekYdGfO";
// const stripePublishableKey = "pk_live_51MLluXJE261KommPzJdT7HyWS9qcQ7HtfwoW6h1mSZLyuQRRcJTvQb9bPejIt0YaRTULhTbjVWVbQaOEN6DpiOKr009FqFOb3e";
// const stripeStatusApiURL = "https://stripe-status-apwpc6wywa-uc.a.run.app";
// const stripeCustomerPortalUrl ="https://billing.stripe.com/p/login/cN216lb28dyk8eI3cc";
// const starterProducts = ["prod_OMkrCUWjGL3VOG", "prod_OMkrBmqXm85GZN"];
// const proProducts = ["prod_OMkrKJbLecrGJ3", "prod_OMkr8QWWJdSuJU"];

subscriptionStatusApiURL = "https://us-central1-easyllm.cloudfunctions.net/lemon-status";
subscriptionCustomerPortalUrl ="https://easyllm.lemonsqueezy.com/billing";
starterProducts = [129442, 129444];
proProducts = [129441, 129443];
lemonCheckOutId = "77262963-50a9-469e-98ac-6b79dcb281b9";
}

export default firebaseConfig;
export {firebaseConfig,reCAPTCHASecretKey,apiUrl, serviceUrl,
  subscriptionStatusApiURL, subscriptionCustomerPortalUrl, starterProducts, proProducts,lemonCheckOutId, env
};