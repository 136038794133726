import { Box , Typography, Link} from '@mui/material'

const ReadMore = ({link, urlName, styleCode}) => {
    return (
        <Box display={"flex"} flexDirection={"row"} mt={1} gap={1} alignItems={"center"} className={styleCode}>
        <Typography>Docs : </Typography>
        <Link underline="none"href={link} target="_blank">{urlName}</Link>
        </Box>
    )
}

export default ReadMore;