/* Page for displaying user profile and options to update profile details*/

import React, { useState, useEffect, useContext } from 'react';
import { TextField, Button, Typography, Box, Snackbar, Alert, Paper } from '@mui/material';
import { styled } from '@mui/system';
import { getFirestore, getDoc, doc, updateDoc} from 'firebase/firestore';
import { endService } from '../utils/serviceManagement';
import { AppContext } from "../AppContext";
import app from '../firebase-app';
import  ReadMore from './ReadMore';

const CssTextField = styled(props => (
  <TextField variant="standard" {...props} />
))(({ theme }) => ({
  '& .MuiFormLabel-root': {
    color: theme.palette.primary.main,
    fontSize: '1.2rem',
  },
}));

const firestore = getFirestore(app);
const currentUserId = localStorage.getItem('userId')

const Settings = ({setSnackBar}) => {
  const [openAIKey, setOpenAIKey] = useState('');
  const [wandbKey, setWandbKey] = useState('');
  const [wandbCookie, setWandbCookie] = useState('');
  const [cohereKey, setCohereKey] = useState('');
  const [llamasExchangeKey, setLlamasExchangeKey] = useState('');
  const [gsBucketName, setGsBucketName] = useState('');
  const [gcpLocation, setGcpLocation] = useState('');
  const [gcpServiceAccount, setGcpServiceAccount] = useState('');
  const {loading, setLoading} = useContext(AppContext);

  const getUserSettings = async (uid) => {
    const settingsRef = doc(firestore, "app-users", uid);
    const settingsDoc = await getDoc(settingsRef);
    return settingsDoc.exists() && settingsDoc.data().settings ? settingsDoc.data().settings : {};
  };
  
  const updateSettings = async (uid, settings) => {
    setLoading(true)
    const settingsRef = doc(firestore, "app-users", uid);
    if(settings.openAIKey && settings.wandbKey){
      try {
        const currentSettings = await getUserSettings(uid);
        if(currentSettings.openAIKey){
          if (currentSettings.openAIKey !== settings.openAIKey || currentSettings.wandbKey !== settings.wandbKey) {
            // console.log("ending service");
            const endServiceResult =await endService(uid);
            // if (!endServiceResult) {
            //   console.error("Error ending service");
            // }
            // else{
            //   console.log("Service ended");
            // }
          }
        }
        await updateDoc(settingsRef, { settings: settings });
        setSnackBar({open: true, message: "Settings updated", severity: "success"});
        setLoading(false);
      } catch (error) {
          setSnackBar({open: true, message: "Not able to update settings", severity: "error"});
          setLoading(false);
      }
    }
    else{
      setSnackBar({open: true, message: "Provide both OpenAI API key and Weights and Biases API key", severity: "error"});
      setLoading(false);
    }
  };
  

  useEffect(() => {
    const fetchSettings = async () => {
      const settings = await getUserSettings(currentUserId);
      if(settings){
      setOpenAIKey(settings.openAIKey || '');
      setWandbKey(settings.wandbKey || '');
      setWandbCookie(settings.wandbCookie || '');
      setCohereKey(settings.cohereKey || '');
      setLlamasExchangeKey(settings.llamasExchangeKey || '');
      setGsBucketName(settings.gsBucketName || '');
      setGcpLocation(settings.gcpLocation || '');
      setGcpServiceAccount(settings.gcpServiceAccount || '');
      }
    };

    setLoading(true);
    fetchSettings().then(() => setLoading(false));
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    await updateSettings(currentUserId, { openAIKey, wandbKey, wandbCookie,
      cohereKey, llamasExchangeKey,
      gsBucketName, gcpLocation, gcpServiceAccount
      });
  };

  return (
    <Box 
      alignItems={'center'} 
      display={'flex'} 
      flexDirection={'column'} 
      gap={4} 
      sx={{
        width: {
          xs: '95%',  // mobile
          sm: '80%',  // tablet
          md: '60%'   // desktop
        }
      }}
    >
      <Typography variant="h4">API Keys Settings</Typography>
        <Paper elevation={1} sx={{
          paddingX: 8,
          paddingY: 4,
          width: '100%', 
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}>
        <CssTextField
          label="OpenAI API Key"
          value={openAIKey}
          onChange={(e) => setOpenAIKey(e.target.value)}
          fullWidth
          margin="normal"
          required={true}
          helperText="For finetuning OpenAI models - mandatory field"
          autoComplete="off"
        />
        <CssTextField
          label="Weights and Biases API Key"
          value={wandbKey}
          onChange={(e) => setWandbKey(e.target.value)}
          fullWidth
          margin="normal"
          required={true}
          helperText="For Weights and Biases integration - mandatory field"
          autoComplete="off"
        />
        <Button sx={{mt: 2}} type="submit" variant="contained" color="primary" onClick={handleSubmit}>
          Save
        </Button>
        </Paper>
        <Paper elevation={1} sx={{
          paddingX: 8,
          paddingY: 4,
          width: '100%', 
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}>
        <CssTextField
          label="Llamas Exchange API Key"
          value={llamasExchangeKey}
          onChange={(e) => setLlamasExchangeKey(e.target.value)}
          fullWidth
          margin="normal"
          helperText="For finetuning Llama and Mistral models in Llamas Exchange"
          autoComplete="off"
        />
        <CssTextField
          label="Cohere API Key"
          value={cohereKey}
          onChange={(e) => setCohereKey(e.target.value)}
          fullWidth
          margin="normal"
          autoComplete="off"
        />
        <CssTextField
          label="Weights and Biases Browser Cookie"
          value={wandbCookie}
          onChange={(e) => setWandbCookie(e.target.value)}
          fullWidth
          margin="normal"
          autoComplete="off"
        />
        <Button sx={{mt: 2}} type="submit" variant="contained" color="primary" onClick={handleSubmit}>
          Save
        </Button>
        </Paper>
        <Paper elevation={1} sx={{
          paddingX: 8,
          paddingY: 4,
          width: '100%', 
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}>
        <Typography variant="h4">GCP Service Account Details</Typography>
        <CssTextField
          label="Service Account JSON"
          value={gcpServiceAccount || ''}
          onChange={(e) => setGcpServiceAccount(e.target.value)}
          fullWidth
          margin="normal"
          multiline
          rows={4}
          autoComplete="off"
          variant="outlined"
          helperText="Paste the JSON in your GCP service account key file here. This is required for finetuning Gemini models in GCP Vertex AI and Google AI Studio."
        />
        <CssTextField
          label="Location (Region)"
          value={gcpLocation || ''}
          onChange={(e) => setGcpLocation(e.target.value)}
          fullWidth
          margin="normal"
          autoComplete="off"
          helperText="Enter the location (region) where you want to deploy your Gemini model. For example, us-central1"
        />
        <CssTextField
          label="GS Bucket Name"
          value={gsBucketName || ''}
          onChange={(e) => setGsBucketName(e.target.value)}
          fullWidth
          margin="normal"
          autoComplete="off"
          helperText="Enter the name of your Google Cloud Storage bucket. This is required for storing and accessing your datasets during finetuning."
        />
        <Button sx={{mt: 2}} type="submit" variant="contained" color="primary" onClick={handleSubmit}>
          Save
        </Button>
        </Paper>
    </Box>
  );
};

const Profile = () => {
    const [name, setName] = useState('');
    const [company, setCompany] = useState('');
    const [snackBar, setSnackBar] = useState({open: false, message: '', severity: 'success'});

    const getUserProfile = async (uid) => {
        const profileRef = doc(firestore, "app-users", uid);
        const profileDoc = await getDoc(profileRef);
        return profileDoc.exists() ? profileDoc.data().profile : null;
    }

    const updateProfile = async (uid, profile) => {
        const profileRef = doc(firestore, "app-users", uid);
        try {
            await updateDoc(profileRef, { profile: profile });
            setSnackBar({open: true, message: "Profile updated", severity: "success"});
        } catch (error) {
            // console.error("Error updating or creating document: ", error);
            setSnackBar({open: true, message: "Not able to update profile", severity: "error"});
        }
    }

    useEffect(() => {
        const fetchProfile = async () => {
            const profile = await getUserProfile(currentUserId);
            if(profile){
            setName(profile.name || '');
            setCompany(profile.company || '');
            }
        };

        fetchProfile();
    }
    , []);

    const handleSubmit = async (event) => {
        event.preventDefault();
        await updateProfile(currentUserId, { name, company });
    }

    return (
        <>  
            <Box display={'flex'} flexDirection={'column'} py={3} gap={2} flexWrap={'wrap'} alignItems={'center'}>
                <Box pb={2} sx={{ display: { xs: 'none', md: 'block' } }}>
                  <ReadMore link={"https://www.easyllm.tech/docs/settings.html/#settings"} urlName={"Setup API keys"} styleCode={'form-help-text form-help-profile'} /> 
                </Box>
                <Settings setSnackBar={setSnackBar} />
                <Box alignItems={'center'} display={'flex'} flexDirection={'column'} mt={4} gap={3} 
                  sx={{
                    width: {
                      xs: '95%',  // mobile
                      sm: '80%',  // tablet
                      md: '60%'   // desktop
                    }
                  }}
                >
                    <Typography variant="h4">Profile</Typography>
                    <Paper elevation={1} sx={{
                      paddingX: 8,
                      paddingY: 4,
                      width: '100%', 
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center'
                    }}>
                    <CssTextField
                        label="Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        fullWidth
                        margin="normal"
                    />
                    <CssTextField
                        label="Company"
                        value={company}
                        onChange={(e) => setCompany(e.target.value)}
                        fullWidth
                        margin="normal"
                    />
                    <Button sx={{mt: 2}} type="submit" variant="contained" color="primary" onClick={handleSubmit}>
                        Save
                    </Button>
                    </Paper>
                </Box>
            </Box>
            <Snackbar open={snackBar.open} autoHideDuration={4000} onClose={() => setSnackBar({open: false})}>
                <Alert severity={snackBar.severity} sx={{ width: '100%' }}>
                    {snackBar.message}
                </Alert>
            </Snackbar>
      </>
    );
}

export default Profile;
