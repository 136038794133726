import React from 'react';
import Fab from '@mui/material/Fab';
import EastIcon from '@mui/icons-material/East';

const FloatingButton = ({ onClick, text, hideIcon }) => {
    return (
        <Fab
            onClick={onClick}
            color="primary"
            aria-label={text}
            variant={text ? "extended" : "circular"}
            sx={{
                position: 'fixed',
                bottom: '16px',
                right: '16px',
                zIndex: 9999,
            }}
        >
            {!hideIcon && (<EastIcon />)}
            {text}
        </Fab>
    );
};

export default FloatingButton;
