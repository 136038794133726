import React, { useState, useEffect, useContext } from 'react';
import {
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TablePagination,
    TableFooter,
    Paper,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    Box,
    TableSortLabel,
    Typography
} from '@mui/material';
import { StyledTableRow, StyledTableCell } from './styledTable';
import {
    Delete as DeleteIcon,
    Warning as WarningIcon
} from '@mui/icons-material';
import {
    getFirestore, collection, query, where, orderBy, getDocs, doc, deleteDoc,
    addDoc, serverTimestamp, limit
} from 'firebase/firestore';
import { FirebaseError } from 'firebase/app';
import { useNavigate } from 'react-router-dom';
import app from '../firebase-app';
import { AppContext } from '../AppContext';
import  ReadMore from './ReadMore';
import { checkSubscriptionPlan } from '../utils/billing';

const db = getFirestore(app);

const Projects = () => {
    const [projects, setProjects] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [totalProjects, setTotalProjects] = useState(0);
    const [orderedBy, setOrderBy] = useState('createdOn');
    const [order, setOrder] = useState('desc');
    const [createProjectOpen, setCreateProjectOpen] = useState(false);
    const [newProjectName, setNewProjectName] = useState('');
    const [newProjectDescription, setNewProjectDescription] = useState('');
    const { loading, setLoading } = useContext(AppContext)
    const [currentProject, setCurrentProject] = useState(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [projectToDelete, setProjectToDelete] = useState(null);
    const [refreshTrigger, setRefreshTrigger] = useState(0);

    const navigate = useNavigate();

    const userId = localStorage.getItem('userId');

    const createSortHandler = (property) => () => {
        const isAsc = orderedBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    useEffect(() => {
        setLoading(true);
        const projectsCollection = collection(db, 'projects');

        const totalProjectsQuery = query(projectsCollection, where('owner', '==', userId));
        getDocs(totalProjectsQuery)
            .then((querySnapshot) => {
                setTotalProjects(querySnapshot.size);
            })
            .catch((error) => {
                if (error instanceof FirebaseError) {
                    if (error.message === "Missing or insufficient permissions.") {
                        setLoading(false);
                        navigate(`/account`);
                        return;
                    } else {
                        // Handle other Firebase errors here
                    }
                } else {
                    // Handle general error
                    // console.log('Error getting projects:', error);
                }
                // console.log('Error getting projects:', error);
            });

        const projectsQuery = query(projectsCollection,
            where('owner', '==', userId),
            orderBy(orderedBy, order),
            limit(rowsPerPage * (page + 1))
        );

        getDocs(projectsQuery)
            .then((querySnapshot) => {
                const projects = querySnapshot.docs.map((doc) => {
                    return { ...doc.data(), id: doc.id };
                });
                setProjects(projects.slice(rowsPerPage * page, rowsPerPage * (page + 1)));
                setLoading(false)
            }
            );

    }, [orderedBy, order, page, rowsPerPage, refreshTrigger]);

    const handleChangeRowsPerPage = (event) => {
        // console.log(event.target.value);
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleCreateProjectClick = () => {
        if(projects.length > 1){
            checkSubscriptionPlan(userId)
            .then((plan) => {})
        }
        setCreateProjectOpen(true);
    };

    const handleCreateProjectClose = () => {
        setCreateProjectOpen(false);
        setNewProjectName('');
        setNewProjectDescription('');
    };

    const handleDeleteClick = (event, project) => {
        event.stopPropagation();
        setProjectToDelete(project);
        setDeleteDialogOpen(true);
    };

    const handleDeleteProjectConfirm = () => {
        if (projectToDelete) {
            const projectDocRef = doc(db, 'projects', projectToDelete.id);
            deleteDoc(projectDocRef)
                .then(() => {
                    setRefreshTrigger(prev => prev + 1);
                    setProjectToDelete(null);
                    setDeleteDialogOpen(false);
                })
                .catch((error) => {
                    // console.log('Error deleting project:', error);
                });
        }
    };

    const handleDeleteDialogClose = () => {
        setDeleteDialogOpen(false);
        setProjectToDelete(null);
    };

    const handleCreateProjectConfirm = () => {
        if (userId) {
            const projectsCollectionRef = collection(db, 'projects');
            addDoc(projectsCollectionRef, {
                name: newProjectName,
                description: newProjectDescription,
                owner: userId,
                teamMembers: [],
                createdOn: serverTimestamp(),
            })
                .then((docRef) => {
                    // refresh the projects list
                    setRefreshTrigger(prev => prev + 1);
                    handleCreateProjectClose();
                })
                .catch((error) => {
                    // console.log('Error creating project:', error);
                });
        }
    };

    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };

    const handleProjectDescriptionChange = (event) => {
        setNewProjectDescription(event.target.value);
    };

    const handleNewProjectNameChange = (event) => {
        setNewProjectName(event.target.value);
    };

    const handleClick = (event, project) => {
        navigate(`/project?id=${project.id}&name=${project.name}`);
    };

    return (
        <Box px={4}>
            <Box display="flex" justifyContent="space-between" mb={2}>
                <Typography variant="h5">Projects</Typography>
                <Button variant="contained" color="primary" onClick={handleCreateProjectClick}>
                    Create Project
                </Button>
            </Box>
            <TableContainer component={Paper} sx={{ mb: 2 }}>
                <Table>
                    <TableHead>
                        <StyledTableRow>
                            <StyledTableCell>
                                <TableSortLabel
                                active={orderedBy === 'name'}
                                direction={orderedBy === 'name' ? order : 'asc'}
                                onClick={createSortHandler('name')}
                                >
                                Name
                                </TableSortLabel>
                            </StyledTableCell>
                            <StyledTableCell>Description</StyledTableCell>
                            <StyledTableCell>
                            <TableSortLabel
                                active={orderedBy === 'createdOn'}
                                direction={orderedBy === 'createdOn' ? order : 'asc'}
                                onClick={createSortHandler('createdOn')}
                                >
                                Created On
                                </TableSortLabel>
                            </StyledTableCell>
                            <StyledTableCell></StyledTableCell>
                            {/* <StyledTableCell>Manage Team</StyledTableCell> */}
                            <StyledTableCell>Delete Project</StyledTableCell>
                        </StyledTableRow>
                    </TableHead>
                    <TableBody>
                        {projects.map((project) => (
                            <StyledTableRow key={project.id}>
                                <StyledTableCell>{project.name}</StyledTableCell>
                                <StyledTableCell>{project.description}</StyledTableCell>
                                <StyledTableCell>{project.createdOn.toDate().toLocaleString()}</StyledTableCell>
                                <StyledTableCell>
                                    <Button
                                        aria-label="open"
                                        aria-controls="open"
                                        aria-haspopup="true"
                                        color="primary"
                                        variant="contained"
                                        onClick={(event) => handleClick(event, project)}
                                    >
                                        View Project
                                    </Button>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <IconButton
                                        aria-label="delete"
                                        aria-controls="delete"
                                        aria-haspopup="true"
                                        onClick={(event) => handleDeleteClick(event, project)}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </StyledTableCell>
                            </StyledTableRow>

                        ))}
                        {projects.length === 0 && (
                            <StyledTableRow>
                                <StyledTableCell colSpan={6} align="center">
                                    You have not created any projects yet. Click the "Create Project" button
                                </StyledTableCell>
                            </StyledTableRow>
                        )}
                    </TableBody>
                    <TableFooter>
                        <StyledTableRow>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 20]}
                                count={totalProjects}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: { 'aria-label': 'rows per page' },
                                    native: true,
                                }}
                                onPageChange={handlePageChange}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </StyledTableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
            <Dialog open={createProjectOpen} onClose={handleCreateProjectClose}>
                <DialogTitle>Create New Project</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Name"
                        type="text"
                        fullWidth
                        value={newProjectName}
                        onChange={handleNewProjectNameChange}
                    />
                    <TextField
                        margin="dense"
                        label="Description"
                        type="text"
                        fullWidth
                        value={newProjectDescription}
                        onChange={handleProjectDescriptionChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCreateProjectClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleCreateProjectConfirm} color="primary">
                        Create
                    </Button>
                </DialogActions>
            </Dialog>
            
            <Box pb={2} sx={{ display: { xs: 'none', md: 'block' } }}>
                <ReadMore link={"https://www.easyllm.tech/docs/project-management.html"} urlName={"Project Management"} styleCode={'form-help-app'} />
            </Box>
            <Dialog
                open={deleteDialogOpen}
                onClose={handleDeleteDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <WarningIcon color="warning" />
                    Confirm Delete
                </DialogTitle>
                <DialogContent>
                    Are you sure you want to delete project "{projectToDelete?.name}"? This action cannot be undone.
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteDialogClose}>Cancel</Button>
                    <Button onClick={handleDeleteProjectConfirm} color="error" autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

export default Projects;