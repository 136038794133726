import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Box, IconButton, Drawer, List, ListItem, ListItemText, MenuItem, Menu} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { subscriptionCustomerPortalUrl} from '../../secrets';
import { AccountCircle} from '@mui/icons-material';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { AppContext } from '../../AppContext';

const Navbar = ({ getStartedLink, handleMenuOpen, anchorEl, handleMenuClose, handleLogout }) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const { darkMode, toggleTheme } = useContext(AppContext);

  const toggleDrawer = (isOpen) => () => {
    setOpenDrawer(isOpen);
  };

  let logoLink = 'https://www.easyllm.tech/';
  // Check if user is logged in
  if (!getStartedLink) {
    logoLink = '/';
  } 

  const handleManageSubscription = () => {
    window.location.href = subscriptionCustomerPortalUrl;
  };

  return (
    <AppBar position="sticky" elevation={1} sx={{ height: '60px' }}>
      <Toolbar>
        <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
          <Typography variant="h6" component={Link} to={logoLink} 
          style={
            { 
              textDecoration: 'none', 
              color: darkMode ? 'white' : 'black' 
            }}>
            Easy<Typography component="span" color="primary" style={{ fontWeight: 'bold', fontSize: 'inherit' }}>
              LLM <Typography component="span" color="grey">Beta</Typography>
            </Typography>
          </Typography>
          {!getStartedLink && (
          <Box display={{ xs: 'flex', sm: 'none' }} justifyContent="flex-end">
            <IconButton onClick={toggleDrawer(true)}>
              <MenuIcon />
            </IconButton>
          </Box>
          )}
          <Box display={{ xs: 'none', sm: 'flex' }} justifyContent="flex-end">
            <Box sx={{ display: 'flex', gap: 2 }}>
              <IconButton onClick={toggleTheme}>
                {darkMode ? <Brightness7Icon /> : <Brightness4Icon />}
              </IconButton>
              {!getStartedLink && (
              <IconButton onClick={handleMenuOpen}>
                <AccountCircle />
              </IconButton>
              )}
            </Box>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
              <MenuItem component={Link} to="/userSettings" onClick={handleMenuClose}>
                Profile
              </MenuItem>
              <MenuItem onClick={handleManageSubscription}> Manage Subscription</MenuItem>
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          </Box>
        </Box>
      </Toolbar>
      <Drawer anchor="right" open={openDrawer} onClose={toggleDrawer(false)}>
        <List sx={{ width: 250 }} onClick={toggleDrawer(false)}>
              <ListItem button component={Link} to="/userSettings">
                <ListItemText primary="Profile" />
              </ListItem>
              <ListItem button onClick={handleManageSubscription}>
                <ListItemText primary="Manage Subscription" />
              </ListItem>
              <ListItem button onClick={handleLogout}>
                <ListItemText primary="Logout" />
              </ListItem>
        </List>
      </Drawer>
    </AppBar>
  );
};

export default Navbar;
